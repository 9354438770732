/**
 * Make sure the state field is a <select> element which can receive states.
 * @param {HTMLElement} $element
 */
function makeDatalist($element) {
	const id = $element?.getAttribute('id');

	if (!id) {
		return;
	}

	$element.dataset.amsStateSupport = 'true';

	const $datalist = document.createElement('datalist');
	$datalist.setAttribute('id', `datalist-${id}`);

	$element.after($datalist);
	$element.setAttribute('list', $datalist.getAttribute('id'));

	return $datalist;
}

/**
 * Given state names keyed by their state code, update the datalist.
 * @param {HTMLDataListElement} $datalist The datalist.
 * @param {object} states The states.
 * @param {string} currentState The current state, if there is one.
 */
function setDatalistStates($datalist, states = {}, currentState = null) {
	// Simple replace the content with new options.
	$datalist.innerHTML = Object.entries(states).map(state => {
		const selected = state[0] === currentState || state[1] === currentState;
		// We use the inverse (name as value and code as label), so that the text input gets a nice value for
		// presentation, and then on form submission we're flipping for storage...
		return `<option value="${state[1]}" ${selected ? 'selected' : ''}>${state[0]}</option>`;
	}).join('');
}

/**
 * Given a state code or name and a country, try and resolve to a state code.
 *
 * @param {string} input The input state code/value.
 * @param {string} countryCode The country code.
 *
 * @return {string|null} The state code if found, otherwise null.
 */
function resolveStateValueToCode(input, countryCode) {
	const states = getStatesForCountryCode(countryCode);

	// It's a valid code already, just return the input.
	if (states[input]) {
		return input;
	}

	// Try and get by name...
	const matches = Object.entries(states)
		.filter(state => state[1] === input);

	// Return the first code.
	if (matches.length) {
		return matches[0][0];
	}

	return null;
}

/**
 * Given a country code, try and get the states, if there are any.
 *
 * @param {string} countryCode The country code.
 *
 * @return {object} The states, keyed by code, valued by name.
 */
function getStatesForCountryCode(countryCode) {
	const allStates = window?.ams_gf_wc_location_codes?.states || {};
	return allStates[countryCode] || {};
}


/**
 *
 * @param {HTMLElement} $container
 */
function setupAddressFieldContainer($container) {
	// Country field is always the 6th field, even when hidden by an address type (eg, Australia, Canada).
	const $country = $container.querySelector('[id^="input_"][id$="_6"]:is(select)');
	// State field is always the 4th field.
	const $state = $container.querySelector('[id^="input_"][id$="_4"]:is(input,select)');

	// Bail due to missing elements. If the country <select> is not present, it could be another type of address
	// field, or perhaps the country/state fields are removed completely (at form editor's discretion).
	if (!$country || !$state) {
		return;
	}

	// The <datalist> for the state field autocompletion.
	const $datalist = makeDatalist($state);

	// This will change as the country selection changes.
	let currentStateOptions = {};
	let currentStateOptionsCount = 0;

	const updateStateOptions = (selectedState) => {
		const selectedCountry = $country.value;
		currentStateOptions = getStatesForCountryCode(selectedCountry);
		currentStateOptionsCount = Object.entries(currentStateOptions).length;
		setDatalistStates($datalist, currentStateOptions, selectedState);
		// Wipe any errors if there are any...
		$state.setCustomValidity('');
	}

	// Track options on change...
	$country.addEventListener('input', () => updateStateOptions());
	// ...and trigger on setup.
	updateStateOptions();

	// If there was a state value, and it has a valid current state option, update the input to the valid state
	// name, as it's more user friendly.
	if ($state.value && currentStateOptions[$state.value]) {
		$state.value = currentStateOptions[$state.value];
	}

	// A callback to check the validity of the field using native browser functionality. We also validate server
	// side to ensure validity there too.
	const assertStateValidity = () => {
		const stateValue = $state.value;

		// Check if there are current state options, and try to resolve the name/code to a code.
		if (currentStateOptionsCount > 0 && !resolveStateValueToCode(stateValue, $country.value)) {
			$state.setCustomValidity(window?.ams_gf_wc_location_codes?.phrases?.invalid_state || 'Select a valid state.');
		} else {
			$state.setCustomValidity('');
		}

		// Client-side validation of state using browser functionality.
		return $state.reportValidity();
	};

	// Set up an input observer to track and validate the change of the state field.
	$state.addEventListener('input', event => assertStateValidity());

	// would be nice to block on form submit, but that completely kills GF's default behaviour after the first
	// invalid submission - the user can never actually submit again after that.
	// $state.closest('form').addEventListener('submit', event => {
	// 	// Resolve the state value to a code.
	// 	const state = resolveStateValueToCode($state.value, $country.value);
	//
	// 	if (!assertStateValidity()) {
	// 		event.stopImmediatePropagation();
	// 		event.preventDefault();
	// 		return false;
	// 	}
	// })

}


export function setupAddressFieldCodeSupport() {
	if (!window['ams_gf_wc_location_codes']) {
		return;
	}

	const countries = window?.ams_gf_wc_location_codes?.countries;
	const states = window?.ams_gf_wc_location_codes?.states;

	if (!countries || !states) {
		console.warn('Wanted to setup address fields with country/state codes, however country and/or state codes had no viable options!');
		return;
	}

	// Get address fields which at least have a state field, this is the one which is most likely to be visible and
	// changing based on country. In some cases the country field might be a [type=hidden] if it's say a Canadian Address
	// type, so we still need to account for that. If the field doesn't _actually_ exist, the setup function will bail
	// accordingly.
	const $candidates = document.querySelectorAll('.gfield--type-address .ginput_complex.ginput_container.has_state');

	if (0 === $candidates.length) {
		return;
	}

	$candidates.forEach(setupAddressFieldContainer);
};


